.consultant-search-input-wrapper > div > input.rbt-input-main.form-control.rbt-input {
    border-radius: 100px;
    width: 100%;
    height: calc(3.125rem + 2px);
    font-size: 0.875rem;
    padding: 0.625rem 1.25rem;
}

.consultant-search-input-wrapper {
  width: 100%;
}


.text-filed-spinner {
  width: 30px;
  height: 30px;
  border: 5px solid;
  border-color: rgb(255, 127, 21) transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  position: absolute;
  top: 20%;
  right: 8px;
  transform: translateY(-50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
.pagination-item {
	&.page-item {
		&.active {
			span {
				background-color: rgb(255, 127, 21);
				border-color: rgb(255, 127, 21);
				color: white;
			}
		}

		.page-link {
			color: #656565;
		}
	}
}

.cer-pagination>.pagination>.page-item>.page-link>svg {
	color: #656565;
}